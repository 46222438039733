<template>
	<Doughnut
		:chart-options="chartOptions"
		:chart-data="createObject(items)"
		:chart-id="chartId"
		:dataset-id-key="datasetIdKey"
		:plugins="plugins"
		:css-classes="cssClasses"
		:styles="styles"
		:width="width"
		:height="height"
	/>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";
import accentColors from "../models/accentColors";
import { mapGetters } from "vuex";
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
	name: "DonutChart",
	components: {
		Doughnut,
	},
	props: {
		items: {
			type: Array,
			default: () => [],
		},
		chartId: {
			type: String,
			default: "doughnut-chart",
		},
		datasetIdKey: {
			type: String,
			default: "label",
		},
		width: {
			type: Number,
			default: 350,
		},
		height: {
			type: Number,
			default: 350,
		},
		cssClasses: {
			default: "",
			type: String,
		},
		styles: {
			type: Object,
			default: () => {},
		},
		plugins: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			chartOptions: {
				responsive: true,
				maintainAspectRatio: true,
			},
		};
	},
	methods: {
		unifyAmounts(type, amount) {
			if (type == "monthly") {
				return amount;
			}
			if (type == "biweekly") {
				return amount * 2;
			}
			if (type == "weekly") {
				return amount * 4;
			}
			if (type == "daily") {
				return amount * 30;
			}
		},
		createObject(dataset) {
			const data = {
				labels: [],
				datasets: [
					{
						backgroundColor: [],
						data: [],
					},
				],
			};
			const labels = dataset.map((data) => {
				return data.name;
			});

			const amount = dataset.map((data) => {
				return this.unifyAmounts(data.type, data.amount);
			});

			const backgroundColor = dataset.map((data) => {
				return data.backgroundColor;
			});

			data.labels = [...labels];
			data.datasets[0].data = [...amount];
			data.datasets[0].backgroundColor = [...backgroundColor];

			return data;
		},
	},
};
</script>
